import { IMAGES, IMAGES_HDPI } from './config'

const $appRootElement = document.querySelector('#app-root')

/**
 * Render a single story frame based on its array index in `window.__APP_DATA__`.
 *
 * @param {number} [frameIndex=0] The frame's index in `window.__APP_DATA__`
 * @return {void}
 */
export const renderFrameAtIndex = (window.renderFrameAtIndex = (
  frameIndex = 0
) => {
  const { title, body, img = null, colors, buttons = [] } = window.__APP_DATA__[
    frameIndex
  ]

  const frameNumber = parseInt(frameIndex) + 1

  const html = []

  html.push(
    `<div class="frame" style="background-color: ${colors.bg}; color: ${colors.text};">`
  )

  html.push(
    `<div class="frame-content" style="background-color: ${colors.bg}; color: ${colors.text}; border-color: ${colors.text};">`
  )

  html.push(
    `<span class="frame-number" style="background-color: ${colors.bg};">${frameNumber}</span>`
  )
  html.push(`<h1 class="frame-title">${title}</h1>`)

  const displayTextAsTwoColumns = img === null
  if (displayTextAsTwoColumns) {
    html.push(
      `<div class="frame-body">
        <div class="frame-body-text-two-columns">
          ${body}
        </div>
      </div>`
    )
  } else {
    const imageSrc = IMAGES[img]
    const imageSrcHdpi = IMAGES_HDPI[img]

    html.push(
      `<div class="frame-body">
        <div class="frame-body-text">
          ${body}
        </div>
        <img
          alt="A minimalistic line-drawing depicting the story on page ${frameNumber}."
          class="frame-image"
          src="${imageSrc}"
          srcset="${imageSrcHdpi} 2x"
        />
      </div>`
    )
  }

  if (buttons.length > 0) {
    html.push(
      `<button class="frame-button frame-button-right" onclick="renderFrameAtIndex(${buttons[0].linkindex})">${buttons[0].text}</button>`
    )
  }
  if (buttons.length > 1) {
    html.push(
      `<button class="frame-button frame-button-left" onclick="renderFrameAtIndex(${buttons[1].linkindex})">${buttons[1].text}</button>`
    )
  }

  html.push('</div> <!-- Close .frame-content -->')
  html.push('</div> <!-- Close .frame -->')

  $appRootElement.innerHTML = html.join('')
})
