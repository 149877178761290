// @NOTE: Workaround for Parcel Bundler, returns a hashed URL path to the static asset so we can make a client-side API request.
export const API_ENDPOINT_URL = require('../data/story.json')

// @NOTE: Workaround for Parcel Bundler loading of static assets.
export const IMAGES = {
  'img/01.png': require('../img/01.png'),
  'img/02.png': require('../img/02.png'),
  'img/03.png': require('../img/03.png'),
  'img/04.png': require('../img/04.png'),
  'img/05.png': require('../img/05.png'),
  'img/06.png': require('../img/06.png'),
  'img/07.png': require('../img/07.png'),
  'img/08.png': require('../img/08.png'),
  'img/09.png': require('../img/09.png'),
  'img/10.png': require('../img/10.png'),
  'img/11.png': require('../img/11.png')
}

// @NOTE: Workaround for Parcel Bundler loading of static assets.
export const IMAGES_HDPI = {
  'img/01.png': require('../img/01@2x.png'),
  'img/02.png': require('../img/02@2x.png'),
  'img/03.png': require('../img/03@2x.png'),
  'img/04.png': require('../img/04@2x.png'),
  'img/05.png': require('../img/05@2x.png'),
  'img/06.png': require('../img/06@2x.png'),
  'img/07.png': require('../img/07@2x.png'),
  'img/08.png': require('../img/08@2x.png'),
  'img/09.png': require('../img/09@2x.png'),
  'img/10.png': require('../img/10@2x.png'),
  'img/11.png': require('../img/11@2x.png')
}
