import { API_ENDPOINT_URL } from './config'
import { renderFrameAtIndex } from './renderer'

/**
 * Application entrypoint.
 *
 * Initializes the client with 1) an API request for the `/story.json` data, 2) * storing that response data inside `window.__APP_DATA__`, then 3) rendering
 * and injecting the app's initial HTML.
 *
 * @return {void}
 */
;(async () => {
  window.__APP_DATA__ = await window
    .fetch(API_ENDPOINT_URL)
    .then(res => {
      if (res.status !== 200) {
        throw new Error(res.status)
      }
      return res.json()
    })
    .then(({ frames }) => frames)
    .catch(err => {
      throw new Error(err)
    })

  renderFrameAtIndex()
})()
